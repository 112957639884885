import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const ReviewsSummary = () => {
  const data = useStaticQuery(query)
  const socialMediaIcons =
    data.socialMediaIcons.siteMetadata.company.contact.socialMedia
  const homeReviewsBg = data.placeholderImage.childImageSharp.fixed
  const [reviews, setReviews] = useState(reviewList)

  const renderReview = ({ key, text, reviewer }) => {
    return (
      <div className="section-reviews-summary__render-box" key={key}>
        <div className="section-reviews-summary__review-text">
          <p className="paragraph">{text}</p>
        </div>
        <div className="section-reviews-summary__reviewer">
          <em className="paragraph">
            {""} {reviewer}
          </em>
        </div>
      </div>
    )
  }

  const handleReviewLinkClick = key => {
    let reviewsCopy = reviews.slice()

    reviewsCopy.forEach(r => {
      r.key === key ? (r.selected = true) : (r.selected = false)
    })

    setReviews(reviewsCopy)
  }

  return (
    <section className="section-reviews-summary">
      <div className="section-reviews-summary__content-container">
        <div className="section-reviews-summary__background">
          <Img fixed={homeReviewsBg} />
        </div>
        <div className="section-reviews-summary__background-overlay" />
        <div className="section-reviews-summary__review-content">
          <div className="section-reviews-summary__review">
            <div className="section-reviews-summary__quote-icon">&quot;</div>
            {renderReview(reviews.filter(r => r.selected)[0])}
            <div className="section-reviews-summary__review-pagination">
              {reviews.map(r => {
                return (
                  <div
                    onClick={() => handleReviewLinkClick(r.key)}
                    className="section-reviews-summary__review-link"
                    key={r.key}
                  >
                    <i
                      className={
                        "icon-full-stop" + (r.selected ? "-closed" : "-open")
                      }
                    ></i>
                  </div>
                )
              })}
            </div>
            <div className="section-reviews-summary__social-media-icons">
              {socialMediaIcons
                .filter(r => r.active)
                .map(i => {
                  return (
                    <a href={i.url} key={i.company}>
                      <i className={i.iconClassName}></i>
                    </a>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReviewsSummary

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "home-reviews-bg.jpg" }) {
      childImageSharp {
        fixed(width: 1920) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    socialMediaIcons: site {
      siteMetadata {
        company {
          contact {
            socialMedia {
              company
              url
              iconClassName
              active
            }
          }
        }
      }
    }
  }
`

const reviewList = [
  {
    key: "amy-l-mcnally",
    text:
      "Exceptional IT service provider. I would recommend Specter Technology Solutions for anyone in my professional network.",
    reviewer: "Amy L. McNally, Communications Coordinator, Evofem Biosciences",
    selected: false,
  },
  {
    key: "daniel-pike",
    text:
      "The I.T. function of a company, whether outsourced or in-house is an integral and intimate part of the company. In the past it was just emails and files, but now I.T. encompasses every aspect of the business. Trust is the #1 requisite when selecting a provider, and it has been a delight to work as a startup with Specter and watch them build an I.T. infrastructure for us in such a quick time that will scale with us as we grow...",
    reviewer:
      "Daniel Pike, GM, Votre Allure PC; President, Votre Allure Labs LLC",
    selected: true,
  },
  {
    key: "connor-l",
    text:
      "In my current job, I have worked with Specter Technologies who was contracted as our off-site IT consultants. Right from day one when myself and the other new hire had our new work stations setup, I never had anything but a great rapport with the Specter team.  Responses were always prompt and clear with time frame for a conversation and/or fix either in person or via remote access.  Having worked for companies of all sizes I am quite used to working with off-site IT personnel and receiving acknowledgement 'tickets' and this was right on par. The Specter team was diligent about prioritizing requests/issues between their other clients.  Working with the team and their processes and procedures was easy and the team was always professional, fun, and personable. I look forward to working with them again in the future!",
    reviewer: "A. L. Connor, Business Development Analyst",
    selected: false,
  },
  {
    key: "carolyn-h",
    text:
      "I have had the pleasure of working with Specter Technologies for our company. And it is so refreshing to work with someone who has the knowledge, works directly with your employees when needed, detail ordinated for all tech levels so everyone is on the same page, and timely. You cannot get a better tech company. I am so glad we chose them for our IT support it has paid us back in ten folds.",
    reviewer: "Carolyn H., Director of Operations, Votre Allure",
    selected: false,
  }
]
