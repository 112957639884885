/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import "./../static/icons8-styles.css"
import NavigationBar from "./navigation/navigation-bar"
import Footer from "./footer"

const Layout = ({ children, landingPage = false }) => {
  const renderNavigation = () => {
    return landingPage ? "" : <NavigationBar />
  }

  const renderFooter = () => {
    return landingPage ? "" : <Footer />
  }

  return (
    <div className="component-layout">
      {renderNavigation()}
      <main>{children}</main>
      {renderFooter()}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  landingPage: PropTypes.bool,
}

export default Layout
