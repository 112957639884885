import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(query)
  const heroImage = data.placeholderImage.childImageSharp.fixed
  const dbaName = data.company.siteMetadata.company.name.dba
  const email = data.company.siteMetadata.company.contact.email

  const siteThankYouUrl = data.company.siteMetadata.siteThankYouUrl

  const utm = {
    source: "Form",
    medium: "Organic",
    campaign: "None",
  }

  const redirectUrl = `${siteThankYouUrl}?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`

  const [checkBoxState, setCheckBoxState] = useState(true)

  const handleCheckBoxClick = () => {
    setCheckBoxState(!checkBoxState)
  }

  return (
    <header>
      <div className="header__content-container">
        <div className="header__hero-image-container">
          <Img fixed={heroImage} />
        </div>
        <div className="header__hero-image-overlay"></div>
        <div className="header__cta-container">
          <div className="header__primary-heading">
            <h1 className="heading-primary">
              We are a the proactive IT solution discerning businesses deserve.{" "}
              <span className="header__cta-container--subtext">
                {dbaName} is an <em>exclusive IT managed services provider</em>{" "}
                based out of the Southern California region. We understand that
                you need fast, stable, secure, and flexible{" "}
                <em>
                  solutions to enable you to be more productive and profitable
                </em>
                . We've worked hard to differentiate ourselves from our
                competitors by doing just that in a uniquely proactive manner.
              </span>
            </h1>
          </div>
          <form
            className="header__cta-form"
            action={"https://formspree.io/" + email}
            method="POST"
          >
            <div className="header__cta-hook">
              <h2 className="heading-secondary">
                Get your free IT health &amp; infrastructure assessment.
              </h2>
            </div>
            <div className="header__cta-input-fields">
              <input name="_next" type="hidden" value={redirectUrl} />
              <input name="campaign_id" type="hidden" value="None" readOnly />
              <input
                type="text"
                name="name"
                className="header__cta-input"
                placeholder="Your Name"
              />
              <input
                type="email"
                name="email"
                className="header__cta-input"
                placeholder="Email@Domain.com"
              />
            </div>
            <div className="header__cta-button">
              <button
                type="submit"
                className="button button--primary button--cta"
              >
                Get Your Free Assessment
              </button>
            </div>
            <div className="header__cta-newsletter">
              <div className="header__cta-newsletter-checkbox">
                <input
                  onClick={handleCheckBoxClick}
                  name="newsletter_checkbox"
                  type="checkbox"
                  defaultChecked
                  checked={checkBoxState}
                />
              </div>
              <div className="header__cta-newsletter-text">
                <p className="paragraph">
                  Please also add me to your mailing list to learn more about
                  how to improve my IT infrastructure and get great offers.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "header-bg.jpg" }) {
      childImageSharp {
        fixed(width: 1920) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    company: site {
      siteMetadata {
        siteThankYouUrl
        company {
          name {
            dba
          }
          contact {
            email
          }
        }
      }
    }
  }
`
