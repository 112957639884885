import React from "react"
import { renderDisplayCard } from "../generic/render-display-card"
import { useStaticQuery, graphql, Link } from "gatsby"

const ReasonsSummary = () => {
  const data = useStaticQuery(query)
  const navigationSlugs = data.navigationSlugs.siteMetadata.navigationSlugs

  return (
    <section className="section-reasons-summary">
      <div className="section-reasons-summary__content-container">
        <div className="section-reasons-summary__heading">
          <h2 className="heading-secondary heading-secondary--heading-underline">
            We're the <em>Smart Choice</em>
          </h2>
        </div>
        <div className="section-reasons-summary__reasons-container">
          {reasonsList.map(s => {
            return renderDisplayCard(s)
          })}
        </div>
        <div className="section-reasons-summary__button">
          <Link
            to={navigationSlugs.find(ns => ns.key === "about").slug}
            className="button button--primary button--primary-outline"
          >
            Learn More
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ReasonsSummary

const reasonsList = [
  {
    key: "productivity",
    title: "Productivity",
    iconMarkup: <i className="icon-up-trend-chart"></i>,
    summary:
      "It's' no secret, effective IT solutions make you more productive. Time is opportunity. Time is money.",
  },
  {
    key: "savings",
    title: "Savings",
    iconMarkup: <i className="icon-money"></i>,
    summary:
      "An in-house IT specialist will cost you about $50 to $100k per year. We  provide superior results at a fraction of the cost.",
  },
  {
    key: "innovation",
    title: "Innovation",
    iconMarkup: <i className="icon-innovation"></i>,
    summary:
      "Your business, like all others, is unique.  Our innovative solutions will be tailored to your needs to help it grow.",
  },
  {
    key: "accountability",
    title: "Accountability",
    iconMarkup: <i className="icon-todo-list"></i>,
    summary:
      "Progress is necessary for all businesses, old and new. We're accountable to you and your needs. We facilitate your progress.",
  },
  {
    key: "goal-oriented",
    title: "Goal-Oriented",
    iconMarkup: <i className="icon-goal"></i>,
    summary:
      "Our goal is to make your business more robust, stronger, and positioned to continue to succeed. Our goal is to improve, to improve you and your business.",
  },
]

const query = graphql`
  query {
    navigationSlugs: site {
      siteMetadata {
        navigationSlugs {
          key
          text
          slug
          active
          button
        }
      }
    }
  }
`
