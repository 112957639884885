import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Packages = () => {
  const data = useStaticQuery(query)
  const navigationSlugs = data.navigationSlugs.siteMetadata.navigationSlugs

  return (
    <section className="section-packages">
      <div className="section-packages__page-container">
        <div className="section-packages__heading">
          <h2 className="heading-secondary heading-secondary--heading-underline-white">
            See Our <em>Membership Packages</em>
          </h2>
        </div>
        <div className="section-packages__content-container">
          <div className="section-packages__package section-packages__package--platinum">
            <div className="section-packages__header">
              <div className="section-packages__package-heading">
                <h3 className="heading-tertiary">
                  <span>Standard Member</span>
                </h3>
              </div>
              <div className="section-packages__icon section-packages__icon--most-comprehensive">
                <i className="icon-shield"></i>
              </div>
              <div className="section-packages__icon-comment">Get Covered!</div>
              <div className="section-packages__package-features-list">
                <ul>
                  <li className="section-packages__list-item">
                    <em>Unlimited Remote</em>
                  </li>
                  <li className="section-packages__list-item">
                    <em>24x7 Monitoring</em>
                  </li>
                  <li className="section-packages__list-item">
                    <em>Improve Security</em>
                  </li>
                  <li className="section-packages__list-item">
                    Mobile Devices
                  </li>
                  <li className="section-packages__list-item">Servers</li>
                  <li className="section-packages__list-item">Workstations</li>
                  <li className="section-packages__list-item">
                    Network Devices
                  </li>
                  <li className="section-packages__list-item">Backup</li>
                  <li className="section-packages__list-item">Reporting</li>
                  <li className="section-packages__list-item">More...</li>
                </ul>
              </div>
            </div>
            <div className="section-packages__footer">
              <div className="section-packages__package-button">
                <Link
                  to={navigationSlugs.find(s => s.key === "contact").slug}
                  className="button button--primary"
                >
                  Become a Member
                </Link>
              </div>
              <div className="section-packages__learn-more">
                <Link to={navigationSlugs.find(s => s.key === "services").slug}>
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="section-packages__package section-packages__package--gold">
            <div className="section-packages__header">
              <div className="section-packages__package-heading">
                <h3 className="heading-tertiary">
                  <span>Gold Member</span>
                </h3>
              </div>
              <div className="section-packages__icon section-packages__icon--best-seller">
                <i className="icon-best-seller"></i>
              </div>
              <div className="section-packages__icon-comment">
                <em>Best Seller!</em>
              </div>
              <div className="section-packages__package-features-list">
                <ul>
                  <li className="section-packages__list-item">
                    <em>Unlimited On-site</em>
                  </li>
                  <li className="section-packages__list-item">
                    <em>Unlimited Remote</em>
                  </li>
                  <li className="section-packages__list-item">
                    <em>24x7 Monitoring</em>
                  </li>
                  <li className="section-packages__list-item">
                    <em>Improve Security</em>
                  </li>
                  <li className="section-packages__list-item">
                    Mobile Devices
                  </li>
                  <li className="section-packages__list-item">Servers</li>
                  <li className="section-packages__list-item">Workstations</li>
                  <li className="section-packages__list-item">
                    Network Devices
                  </li>
                  <li className="section-packages__list-item">Backup</li>
                  <li className="section-packages__list-item">Reporting</li>
                  <li className="section-packages__list-item">More...</li>
                </ul>
              </div>
            </div>
            <div className="section-packages__footer">
              <div className="section-packages__package-button">
                <Link
                  to={navigationSlugs.find(s => s.key === "contact").slug}
                  className="button button--primary"
                >
                  Become a Member
                </Link>
              </div>
              <div className="section-packages__learn-more">
                <Link to={navigationSlugs.find(s => s.key === "services").slug}>
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Packages

const query = graphql`
  query {
    navigationSlugs: site {
      siteMetadata {
        navigationSlugs {
          key
          text
          slug
          active
          button
        }
      }
    }
  }
`
