import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const SecondCTA = () => {
  const data = useStaticQuery(query)
  const email = data.company.siteMetadata.company.contact.email

  const siteThankYouUrl = data.company.siteMetadata.siteThankYouUrl

  const utm = {
    source: "Form",
    medium: "Organic",
    campaign: "None",
  }

  const redirectUrl = `${siteThankYouUrl}?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`

  return (
    <section className="section-second-cta">
      <div className="section-second-cta__content-container">
        <div className="section-second-cta__heading">
          <h2 className="heading-secondary heading-secondary--heading-underline">
            Want to be{" "}
            <span className="section-second-cta__cta-heading-colored">
              <em>more productive?</em>
            </span>
          </h2>
        </div>
        <div className="section-second-cta__cta-subtext">
          <p className="paragraph">
            Without a doubt,{" "}
            <em>great IT solutions will make you more productive</em>. If you're
            intersted in learning more about what you can do to be more
            effecient, more effective, and ultimately do more for your business,
            then <em>sign-up for our mailing list</em>!
          </p>
        </div>
        <label
          className="section-second-cta__cta-newsletter-label"
          htmlFor="cta-input"
        >
          Sign up for our newsletter!
        </label>
        <form
          name="cta-input"
          className="section-second-cta__cta-newsletter-form"
          action={"https://formspree.io/" + email}
          method="POST"
        >
          <input name="_next" type="hidden" value={redirectUrl} />
          <input name="campaign_id" type="hidden" value="None" readOnly />
          <input name="email" type="text" placeholder="Email@Domain.com" />
          <input
            name="newsletter_checkbox"
            type="checkbox"
            checked={true}
            hidden={true}
          />
          <button type="submit" className="button button--primary">
            Sign Up!
          </button>
        </form>
      </div>
    </section>
  )
}

export default SecondCTA

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "header-bg.jpg" }) {
      childImageSharp {
        fixed(width: 1920) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    company: site {
      siteMetadata {
        siteThankYouUrl
        company {
          name {
            dba
          }
          contact {
            email
          }
        }
      }
    }
  }
`
