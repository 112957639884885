import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/home/header"
import ServicesSummary from "../components/home/services-summary"
import ReviewsSummary from "../components/home/reviews"
import SecondCTA from "../components/home/second-cta"
import ReasonsSummary from "../components/home/reasons-summary"
import Packages from "../components/home/packages"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        description="We're the IT managed service provider you've been looking for. Excellence and simplicity."
      />
      <div className="page-home">
        <Header />
        <ReasonsSummary />
        <ReviewsSummary />
        <ServicesSummary />
        <Packages />
        <SecondCTA />
      </div>
    </Layout>
  )
}

export default IndexPage
