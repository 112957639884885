import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(query)
  const name = data.companyInfo.siteMetadata.company.name
  const locationList = data.companyInfo.siteMetadata.company.location
  const location = locationList.find(l => l.key === "headquarters")
  const contact = data.companyInfo.siteMetadata.company.contact
  const socialMedia = data.companyInfo.siteMetadata.company.contact.socialMedia
  const navigationSlugs = data.navigationSlugs.siteMetadata.navigationSlugs
  const contactSlug = navigationSlugs.find(s => s.key === "contact")

  const scrollToTop = (ref) => window.scrollTo(0, 0)

  return (
    <footer className="section-footer">
      <div className="section-footer__content-container">
        <div className="section-footer__footer-content">
          <div className="section-footer__row">
            <div className="section-footer__column section-footer__column--left">
              <h2 className="heading-secondary">Contact Us</h2>
              <a
                className="section-footer__link"
                href={"mailto:" + contact.email}
              >
                {contact.email}
              </a>
              <a className="section-footer__link" href={"tel:" + contact.phone}>
                {contact.phone}
              </a>
            </div>
            <div className="section-footer__column section-footer__column--center">
              <h2 className="heading-secondary">Follow on Social Media</h2>
              <div className="section-footer__icons">
                {socialMedia
                  .filter(s => s.active)
                  .map(i => {
                    return (
                      <a href={i.url} key={i.company}>
                        <i className={i.iconClassName}></i>
                      </a>
                    )
                  })}
              </div>
            </div>
            <div className="section-footer__column section-footer__column--right">
              <h2 className="heading-secondary">Location</h2>
              <a
                href="https://goo.gl/maps/eRP91o3i8hGMRsYM6"
                className="section-footer__link"
              >
                {location.street}
              </a>
              <a
                href="https://goo.gl/maps/eRP91o3i8hGMRsYM6"
                className="section-footer__link"
              >
                {`${location.city}, ${location.state} ${location.zip}`}
              </a>
            </div>
          </div>
          <div className="section-footer__row">
            <div className="section-footer__button">
              <Link
                to={contactSlug.slug}
                className="button button--large button--white-outline"
              >
                {contactSlug.text}
              </Link>
            </div>
          </div>
          <div className="section-footer__row">
            <div className="section-footer__copyright-info">
              <p className="paragraph">
                Copyright {new Date().getFullYear()} - {name.legal} DBA{" "}
                {name.dba}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-footer__top-link-container">
        <div className="section-footer__top-link-content">
          <button onClick={scrollToTop}>
            Top of Page <i className="icon-chevron-up"></i>{" "}
          </button>
        </div>
      </div>
    </footer>
  )
}

export default Footer

const query = graphql`
  {
    navigationSlugs: site {
      siteMetadata {
        navigationSlugs {
          key
          text
          slug
        }
      }
    }
    companyInfo: site {
      siteMetadata {
        company {
          name {
            legal
            dba
          }
          location {
            key
            name
            street
            city
            state
            zip
          }
          contact {
            email
            phone
            socialMedia {
              company
              url
              iconClassName
              active
            }
          }
        }
      }
    }
  }
`
