import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const NavigationBar = () => {
  const data = useStaticQuery(query)
  const brandLogo = data.placeholderImage.childImageSharp.fluid
  const phoneNumber = data.phoneNumber.siteMetadata.company.contact.phone
  const navigationSlugs = data.navigationSlugs.siteMetadata.navigationSlugs
  const [mobileMenuIsVisible, setMobileMenuIsVisible] = useState(false)

  const handleMenuClick = () => {
    setMobileMenuIsVisible(!mobileMenuIsVisible)
  }

  const getHomeSlug = () => {
    let homeSlug = ""
    try {
      homeSlug = navigationSlugs.find(s => s.key === "home").slug
    } catch {
      console.log(
        "Failed to identify structured slug for the home page. Will default to '/'."
      )
      homeSlug = "/"
    }
    return homeSlug
  }

  const renderNavigationListItems = (navLinks, inline) => {
    return (
      <ul>
        {navLinks
          .filter(link => link.active)
          .map(link => {
            return (
              <li
                className={
                  inline
                    ? "component-navigation__navigation-list-item component-navigation__navigation-list-item--inline"
                    : "component-navigation__navigation-list-item"
                }
                key={link.key}
              >
                <Link
                  onClick={inline ? null : handleMenuClick}
                  className={
                    link.button
                      ? "button button--primary"
                      : "component-navigation__navigation-link"
                  }
                  to={link.slug}
                >
                  {link.text}
                </Link>
              </li>
            )
          })}
      </ul>
    )
  }

  return (
    <nav className="component-navigation">
      <div className="component-navigation__content-container">
        <div className="component-navigation__brand-logo">
          <Link to={getHomeSlug()}>
            <Img fluid={brandLogo} />
          </Link>
        </div>
        <a
          className="component-navigation__cta-link"
          href={"tel:" + phoneNumber}
        >
          <div className="component-navigation__cta">
            <div className="component-navigation__cta-icon">
              <i className="icon-phone"></i>
            </div>
            <div className="component-navigation__cta-text">
              <div className="component-navigation__cta-phone-label">
                Southern CA - Call Now!
              </div>
              <div className="component-navigation__cta-phone-number">
                {phoneNumber}
              </div>
            </div>
          </div>
        </a>
        <div className="component-navigation__navigation-list">
          {renderNavigationListItems(navigationSlugs, true)}
        </div>
        <div className="component-navigation__navigation-menu-expand-button">
          <div
            onClick={handleMenuClick}
            className={
              "component-navigation__navigation-menu-expand-button-closed-state" +
              (!mobileMenuIsVisible
                ? "  component-navigation__navigation-menu-expand-button-closed-state--visible"
                : "")
            }
          >
            <i className="icon-menu-squared"></i>
          </div>
          <div
            onClick={handleMenuClick}
            className={
              "component-navigation__navigation-menu-expand-button-open-state" +
              (mobileMenuIsVisible
                ? " component-navigation__navigation-menu-expand-button-open-state--visible"
                : "")
            }
          >
            <i className="icon-times"></i>
          </div>
        </div>
      </div>
      <div
        className={
          "component-navigation__mobile-menu" +
          (mobileMenuIsVisible
            ? "  component-navigation__mobile-menu--visible"
            : "")
        }
      >
        {renderNavigationListItems(navigationSlugs, false)}
      </div>
    </nav>
  )
}

export default NavigationBar

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "sts-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    navigationSlugs: site {
      siteMetadata {
        navigationSlugs {
          key
          text
          slug
          active
          button
        }
      }
    }
    phoneNumber: site {
      siteMetadata {
        company {
          contact {
            phone
          }
        }
      }
    }
  }
`
