import React from "react"
import { renderDisplayCard } from "../generic/render-display-card"
import { useStaticQuery, graphql, Link } from "gatsby"

const ServicesSummary = () => {
  const data = useStaticQuery(query)
  const navigationSlugs = data.navigationSlugs.siteMetadata.navigationSlugs

  return (
    <section className="section-services-summary">
      <div className="section-services-summary__content-container">
        <div className="section-services-summary__heading">
          <h2 className="heading-secondary heading-secondary--heading-underline">
            We're a <em>Comprehensive IT Solution</em>
          </h2>
        </div>
        <div className="section-services-summary__services-container">
          {servicesList.map(s => {
            return renderDisplayCard(s)
          })}
        </div>
        <div className="section-services-summary__button">
          <Link
            to={navigationSlugs.find(ns => ns.key === "services").slug}
            className="button button--primary button--primary-outline"
          >
            Learn More
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ServicesSummary

const servicesList = [
  {
    key: "security",
    title: "Security",
    iconMarkup: <i className="icon-secure-pc"></i>,
    summary:
      "Hackers are costing companies more than ever these days. Don't be a victim. Let us secure you!",
  },
  {
    key: "backup",
    title: "Backup",
    iconMarkup: <i className="icon-cloud-backup"></i>,
    summary:
      "Reliable backup methods are essential to a modern companies success. Accidents will happen!",
  },
  {
    key: "telecom",
    title: "Telecom",
    iconMarkup: <i className="icon-telecom-head"></i>,
    summary:
      "Communicate smarter with voice-over-ip (VOIP). Cheaper, more scalable, and better sound quality.",
  },
  {
    key: "migrations",
    title: "Migrations",
    iconMarkup: <i className="icon-migrations"></i>,
    summary:
      "Transferring data is dangerous. But, so too is using old technology. We help you safely advance.",
  },
  {
    key: "networking",
    title: "Networking",
    iconMarkup: <i className="icon-networks"></i>,
    summary:
      "Connectivity is key to success. We help the members of your business connect and share effectively.",
  },
]

const query = graphql`
  query {
    navigationSlugs: site {
      siteMetadata {
        navigationSlugs {
          key
          text
          slug
          active
          button
        }
      }
    }
  }
`
